(function ($, window, document, undefined) {

	$(function () {

		// Remove no-js tag from HTML
		$('html').removeClass('no-js');

		// Smooth scroll to top
		var page = $('html, body');

		$('.footer-up').on('click', function(e) {
			e.preventDefault();

			// Stop scrolling when user interacts
			page.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function() {
				page.stop()
			});

			// Scroll to position
			page.animate({ scrollTop: 0 }, 300, function() {
				page.off('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove')
			})
		});

		// Validate all forms
		$('form').each(function() {
			$(this).validate({
				// Also validate hidden
				ignore: [],

				highlight: function (element, errorClass, validClass) {
					var element = $(element),
						that = element;

					// if special parent
					if (element.closest('.input-wrapper').length)
					{
						var that = element.closest('.input-wrapper');
					}
					else
					// customSelect field
					if (element.hasClass('hasCustomSelect'))
					{
						var that = element.siblings();
					}
					else
					// customUpload field
					if (element.attr('id') == 'form-attachment')
					{
						var that = element.siblings('.custom-upload');
					}

					that.addClass(errorClass).removeClass(validClass);
				},
				unhighlight: function (element, errorClass, validClass) {
					var element = $(element),
						that = element;

					// if special parent
					if (element.closest('.input-wrapper').length)
					{
						var that = element.closest('.input-wrapper');
					}
					else
					// customSelect field
					if (element.hasClass('hasCustomSelect'))
					{
						var that = element.siblings();
					}
					else
					// customUpload field
					if (element.attr('id') == 'form-attachment')
					{
						var that = element.siblings('.custom-upload');
					}

					that.removeClass(errorClass).addClass(validClass);
				},

				// Don't show label
				errorElement: false,
				errorPlacement: function() {
					return true;
				}
			});
		});
	})


})(jQuery, window, document)
